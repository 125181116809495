<template>
  <footer id="footer" class="footer mt-auto py-3">
    <b-container>
      <b-row class="mx-auto">
        <b-col>
          <p>
            {{ organisation.companyName }} © 2020
            <a class="footer-link" :href="'mailto:' + organisation.contactEmail">{{ organisation.contactEmail }}</a>
          </p>
        </b-col>
        <b-col>
          <address>
            {{ organisation.postalAddress }}<br />
            {{ organisation.zipCode }} {{ organisation.city }}<br />
          </address>
        </b-col>
        <b-col>
          <b-button variant="link" @click="showModal(organisation.privacyPolicies)">{{
            $t('organisation.privacyPolicies')
          }}</b-button>
        </b-col>
        <b-col>
          <b-button variant="link" @click="showModal(organisation.imprint)">{{ $t('organisation.imprint') }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import { ApiMixin } from '@/mixins/ApiMixin'

export default {
  name: 'AppFooter',
  mixins: [ApiMixin],
  computed: {
    organisation() {
      return this.$store.getters.getOrganisation
    }
  },
  mounted() {
    this.loadPublicOrganisation()
  },
  methods: {
    showModal(content) {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: content } })
      this.$bvModal.msgBoxOk([titleVNode], {
        buttonSize: 'sm',
        centered: true,
        size: 'lg',
        scrollable: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#footer {
  background-color: var(--light);
}

#footer > .container {
  width: 100%;
}

a {
  text-decoration: underline;
  display: block;
  color: var(--gray-900);

  &:hover {
    color: var(--secondary);
  }
}
</style>

<template>
  <div id="app" class="d-flex flex-column h-100">
    <AppHeader />

    <main class="flex-shrink-0 container-fluid px-5">
      <router-view></router-view>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'

export default {
  name: 'app',
  components: { AppHeader, AppFooter }
}
</script>

<style lang="scss">
#app > main {
  padding-top: 140px;
  padding-bottom: 5rem;
}
</style>

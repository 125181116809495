import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import { UserRole } from '@/constants/UserRole'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    token: false,
    currentUser: '',
    locale: 'de',
    config: undefined,
    organisation: undefined,
    logoutErrorMessageKey: '',
    logoutInProgress: false
  },
  mutations: {
    setToken(state, token) {
      axios.defaults.headers.common['Authorization'] = token
      state.token = token
    },
    clearToken(state) {
      axios.defaults.headers.common['Authorization'] = undefined
      state.token = false
    },
    setLogoutErrorMessageKey(state, message) {
      state.logoutErrorMessageKey = message
    },
    setLogoutInProgress(state, inProgress) {
      state.logoutInProgress = inProgress
    },
    clearLogoutErrorMessageKey(state) {
      state.logoutErrorMessageKey = ''
    },
    setCurrentUser(state, apiUser) {
      state.currentUser = apiUser
    },
    clearCurrentUser(state) {
      state.currentUser = {}
    },
    setLocale(state, locale) {
      axios.defaults.headers.common['Accept-Language'] = locale
      state.locale = locale
    },
    setConfig(state, config) {
      state.config = config
    },
    setOrganisation(state, organisation) {
      state.organisation = organisation
    }
  },
  actions: {
    async fetchConfig({ commit }) {
      const runtimeConfig = await fetch('/config.json')
      commit('setConfig', await runtimeConfig.json())
    }
  },
  getters: {
    getToken: state => {
      return state.token
    },
    isTokenSet: state => {
      return state.token !== false
    },
    getLogoutErrorMessageKey: state => {
      return state.logoutErrorMessageKey
    },
    getCurrentUser: state => {
      return state.currentUser
    },
    isCurrentUserPresent: state => {
      return state.currentUser !== ''
    },
    isCurrentUserAdmin: state => {
      return state.currentUser.role === UserRole.Admin
    },
    isCurrentUserProjectManager: state => {
      return state.currentUser.role === UserRole.ProjectManager
    },
    getLocale: state => {
      return state.locale
    },
    getConfig: state => {
      return state.config
    },
    getOrganisation: state => {
      return state.organisation
    }
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import { JsonWebToken } from '@/util/JsonWebToken'
import { UserRole } from '@/constants/UserRole'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "mainLogin" */ '../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "mainLogout" */ '../views/Logout.vue')
  },
  {
    path: '/timetracking',
    name: 'timetracking',
    component: () => import(/* webpackChunkName: "mainTimetracking" */ '../views/Timetracking.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/validation',
    name: 'validation',
    component: () => import(/* webpackChunkName: "mainValidation" */ '../views/Validation.vue'),
    meta: {
      authorize: [UserRole.ProjectManager, UserRole.Admin]
    }
  },
  {
    path: '/estimate',
    name: 'estimate',
    component: () => import(/* webpackChunkName: "mainEstimate" */ '../views/Estimate.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/account/profile',
    name: 'account_profile',
    component: () => import(/* webpackChunkName: "accountProfile" */ '../views/account/Profile.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/account/absence',
    name: 'account_absence',
    component: () => import(/* webpackChunkName: "accountAbsence" */ '../views/account/Absence.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/account/timesheet',
    name: 'account_timesheet',
    component: () => import(/* webpackChunkName: "accountTimesheet" */ '../views/account/Timesheet.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/manage/organisation',
    name: 'manage_organisation',
    component: () => import(/* webpackChunkName: "manageOrganisation" */ '../views/manage/Organisation.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/export',
    name: 'manage_export',
    component: () => import(/* webpackChunkName: "manageExport" */ '../views/manage/Export.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/import',
    name: 'manage_import',
    component: () => import(/* webpackChunkName: "manageImport" */ '../views/manage/import/Import.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/import/:id/errors',
    name: 'manage_import_errors',
    component: () => import(/* webpackChunkName: "manageImport" */ '../views/manage/import/ImportErrors.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/account/begin_password_reset',
    name: 'account_passwordResetRequest',
    component: () =>
      import(/* webpackChunkName: "accountPasswordResetRequest" */ '../views/account/PasswordResetRequest.vue')
  },
  {
    path: '/account/password_reset',
    name: 'account_passwordReset',
    component: () => import(/* webpackChunkName: "accountPasswordReset" */ '../views/account/PasswordReset.vue')
  },
  {
    path: '/manage/contracts',
    name: 'manage_contracts',
    component: () => import(/* webpackChunkName: "manageContracts" */ '../views/manage/Contracts.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/users',
    name: 'manage_users',
    component: () => import(/* webpackChunkName: "manageUsers" */ '../views/manage/Users.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/customers',
    name: 'manage_customers',
    component: () => import(/* webpackChunkName: "manageCustomers" */ '../views/manage/Customers.vue'),
    meta: {
      authorize: [UserRole.ProjectManager, UserRole.Admin]
    }
  },
  {
    path: '/manage/projects',
    name: 'manage_projects',
    component: () => import(/* webpackChunkName: "manageProjects" */ '../views/manage/Projects.vue'),
    meta: {
      authorize: [UserRole.ProjectManager, UserRole.Admin]
    }
  },
  {
    path: '/manage/tickets',
    name: 'manage_tickets',
    component: () => import(/* webpackChunkName: "manageTickets" */ '../views/manage/Tickets.vue'),
    meta: {
      authorize: [UserRole.ProjectManager, UserRole.Admin]
    }
  },
  {
    path: '/manage/ticket_groups',
    name: 'manage_ticket_groups',
    component: () => import(/* webpackChunkName: "manageTicketGroups" */ '../views/manage/TicketGroups.vue'),
    meta: {
      authorize: [UserRole.ProjectManager, UserRole.Admin]
    }
  },
  {
    path: '/manage/absences',
    name: 'manage_absences',
    component: () => import(/* webpackChunkName: "manageAbsences" */ '../views/manage/Absences.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/timesheets',
    name: 'manage_timesheets',
    component: () => import(/* webpackChunkName: "manageTimesheets" */ '../views/manage/Timesheets.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/',
    beforeEnter(to, from, next) {
      if (store.getters.isTokenSet && JsonWebToken.isValid(store.getters.getToken)) {
        next({ name: 'timetracking' })
      } else {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/error/403_forbidden',
    name: '403',
    component: () => import(/* webpackChunkName: "error403" */ '../views/errors/403Forbidden.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "error404" */ '../views/errors/404NotFound.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  if (authorize) {
    if (store.getters.isTokenSet) {
      if (JsonWebToken.isValid(store.getters.getToken)) {
        const currentUserRole = store.getters.getCurrentUser.role
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUserRole)) {
          // role not authorised so redirect to 403 page
          return next({ name: '403' })
        } else {
          next()
        }
      } else {
        store.commit('setLogoutErrorMessageKey', 'errors.not-authorized-message')
        next({
          path: '/logout',
          query: {
            redirectUrl: to.path
          }
        })
      }
    } else {
      next({
        path: '/login',
        query: {
          redirectUrl: to.path
        }
      })
    }
  } else {
    next()
  }
})

export default router

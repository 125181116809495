import axios from 'axios'

export const ApiMixin = {
  mounted() {
    axios.defaults.baseURL = this.$store.getters.getConfig.API_BASE_URL
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.withCredentials = true
    axios.defaults.headers.common['Authorization'] = this.$store.getters.getToken
    axios.defaults.headers.common['Accept-Language'] = this.$store.getters.getLocale
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isTokenSet
    },
    isAdmin() {
      return this.$store.getters.isCurrentUserAdmin
    },
    isProjectManager() {
      return this.$store.getters.isCurrentUserProjectManager
    },
    isVacationEnabled() {
      return this.$store.getters.getOrganisation.vacationManagementEnabled
    },
    isInvoicingEnabled() {
      return this.$store.getters.getOrganisation.invoiceManagementEnabled
    }
  },
  methods: {
    loadPublicOrganisation() {
      return this.getRequest('/organisations/public').then(res => this.$store.commit('setOrganisation', res.data))
    },
    setAuthHeader(token) {
      axios.defaults.headers.common['Authorization'] = token
    },
    /**
     * @param method
     * @param url
     * @param data
     * @param {RequestConfig}  config  A RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    apiRequest(method, url, data, config) {
      return axios
        .request(Object.assign({}, { method: method, url: url, data: data }, config.axiosConfig))
        .then(res => {
          if (config.successCallback) {
            return config.successCallback(res)
          }
          return res
        })
        .catch(err => {
          return this.handleError(err, config)
        })
    },
    /**
     * @param {string}         url       The request URL
     * @param {RequestConfig}  [config]  An optional RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    getRequest(url, config) {
      return this.apiRequest('get', url, null, config != null ? config : new RequestConfig())
    },
    /**
     * @param {string}         url       The request URL
     * @param {any}            data      The request payload
     * @param {RequestConfig}  [config]  An optional RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    postRequest(url, data, config) {
      return this.apiRequest('post', url, data, config != null ? config : new RequestConfig())
    },
    /**
     * @param {string}         url       The request URL
     * @param {RequestConfig}  [config]  An optional RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    deleteRequest(url, config) {
      return this.apiRequest('delete', url, null, config != null ? config : new RequestConfig())
    },
    /**
     * @param {string}         url       The request URL
     * @param {any}            data      The request payload
     * @param {RequestConfig}  [config]  An optional RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    patchRequest(url, data, config) {
      return this.apiRequest('patch', url, data, config != null ? config : new RequestConfig())
    },
    /**
     * @param {string}         url       The request URL
     * @param {any}            data      The request payload
     * @param {RequestConfig}  [config]  An optional RequestConfig object with success/error messages and optional AxiosRequestConfig settings.
     */
    putRequest(url, data, config) {
      return this.apiRequest('put', url, data, config != null ? config : new RequestConfig())
    },
    handleError(error, requestConfig) {
      // catch CORS errors, API down, etc.
      if (error.response === undefined && error.toString() === 'Error: Network Error') {
        this.displayError500()
      }
      // catch 500 INTERNAL SERVER ERROR
      else if (error.response !== undefined && error.response.status === 500) {
        this.displayError500()
      }
      // catch 401 UNAUTHORIZED, JWT expired, etc.
      else if (error.response !== undefined && error.response.status === 401) {
        if (!this.$store.state.logoutInProgress) {
          this.$store.commit('setLogoutInProgress', true)
          if (this.isLoggedIn) {
            this.$store.commit('setLogoutErrorMessageKey', 'errors.not-authorized-message')
          }
          // TODO don't redirect to logout but display a login modal to keep to user on the site with its unsaved data
          this.$router
            .push({
              name: 'logout',
              query: {
                redirectUrl: this.$route.fullPath
              }
            })
            .catch(err => console.debug(err))
        }
      }
      // undefined errors
      // display error message or re-throw error so that the following catches can handle it
      else if (requestConfig.errorCallback) {
        requestConfig.errorCallback(error)
      }
      // RequestConfig object provides no error callback function
      else {
        // API response is available, read data from default SpringBoot data object and display as error toast
        if (error.response !== undefined && error.response.data !== undefined) {
          this.displayError(`${error.response.data.error}: ${error.response.data.message}`)
        }
        // API response not available, try to read the error object as string
        else if (error.toString().length > 0) {
          this.displayError(error.toString())
        }
      }

      // re-throw the error in any case so that the caller can implement additional catch()'es on the AxiosPromise
      throw error
    }
  }
}

/**
 * RequestConfig class to be used to configure an API request with the ApiMixin
 *
 * If you want to suppress the default error toast, provide an empty error callback
 * with the onError() method – or implement proper error handling in the error callback.
 */
export class RequestConfig {
  /**
   * @param {function}  successCallback  A method being called on success.
   */
  onSuccess(successCallback) {
    this.successCallback = successCallback
    return this
  }

  /**
   * @param {function}  errorCallback  A method being called on errors. Provide an empty function to suppress the default error toast
   */
  onError(errorCallback) {
    this.errorCallback = errorCallback
    return this
  }

  /**
   * @param {Object}  axiosConfig  An AxiosRequestConfig object.
   */
  withAxiosConfig(axiosConfig) {
    this.axiosConfig = axiosConfig
    return this
  }
}

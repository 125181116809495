<template>
  <div>
    <b-nav-item-dropdown right id="locale-changer">
      <template slot="button-content">
        <v-flag :code="getFlagForLocale(locale)" :hasBorder="false" />
      </template>
      <b-dropdown-item href="#" v-for="l in locales" :key="l.lang" :active="l.lang === locale" @click="locale = l.lang">
        <v-flag :code="l.flag" class="mr-2" :hasBorder="false" /> {{ l.label }}
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <b-tooltip target="locale-changer" :title="$t('general.language')" triggers="hover" />
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'locale-changer',
  data() {
    return {
      locales: {
        en: {
          lang: 'en',
          label: 'English',
          // https://flagpack.xyz/docs/flag-index/
          flag: 'US'
        },
        de: {
          lang: 'de',
          label: 'Deutsch',
          flag: 'DE'
        }
      }
    }
  },
  computed: {
    locale: {
      get: function () {
        return this.$store.getters.getLocale
      },
      set: function (locale) {
        this.$i18n.locale = locale
        this.$store.commit('setLocale', locale)
        dayjs.locale(locale.lang)
      }
    }
  },
  methods: {
    getFlagForLocale(lang) {
      return this.locales[lang].flag
    }
  }
}
</script>

<style lang="scss" scoped>
#locale-changer {
  > a {
    padding-right: 0;

    // align flags pixel-perfect
    .flag.size-m img {
      padding-top: 4px;
    }
  }

  .dropdown-menu {
    min-width: initial;

    .dropdown-item {
      padding-left: 1rem;
      padding-right: 1rem;

      .flag {
        vertical-align: -1px;
      }
    }
  }
}
</style>

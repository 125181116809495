import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueDayjs from 'vue-dayjs-plugin'

import './styles/stylesheet.scss'

import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import Gravatar from 'vue-gravatar'
import Flag from 'vue-flagpack'
import App from './App.vue'
import Ellipsis from 'vue-directive-ellipsis'
import 'vue-directive-ellipsis/dist/ellipsis.umd.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueDayjs)
Vue.component('v-gravatar', Gravatar)
Vue.use(Flag, { name: 'v-flag' })
Vue.directive('ellipsis', Ellipsis)

Vue.config.productionTip = false

store.dispatch('fetchConfig').then(() => {
  return new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
